<template>
    <div class="contentDetail sharePageContent">
        <loading :iscomplete="!detailAll"></loading>
        <div class="vedioStyleBox" v-if="detailAll&&detailAll.video">
            <div class="coverImg" @click="vedioOpenPlay" v-if="thumbBlock">
                <img class="thumbImg" :src="detailAll.thumb.split(',')[0]" alt="">
                <img class="vedioButtonSty" src="../assets/vedioSing.png" alt="">
            </div>
            <video class="vedioContent" :src="detailAll.video" controls  id="datailVedio"></video>
        </div>
        <div class="contentAll" v-if="detailAll">
            <!-- <video :src="detailAll.video" controls  class="vedioContent"></video> -->
            <div class="contentTitltle">{{detailAll.title}}</div>
            <div class="F9efPzfcE5tLO0cygLjpo" v-if="detailAll.matrix_judge==0">
                <div class="_1rui3RTB5brFqhZq5nYylv"></div>
                <div class="Q46HXxwObg2rmy-Ms7f8I">{{detailAll.source}}</div>
                <div class="Q46HXxwObg2rmy-Ms7f8I">{{detailAll.dateline|date}}</div>
                <div class="_1rui3RTB5brFqhZq5nYylv"></div>
            </div>
            <div class="sourceAndTimeJava" v-if="detailAll.matrix_judge==1">
                <div class="sourceFont">
                    <div class="sourceFontImg">
                        <img :src="detailAll.thumb" alt="">
                    </div>
                    <div class="sourceFontnei">
                        <span>{{detailAll.source}}</span>
                        <br/>
                        <span>{{detailAll.dateline|date}}</span>
                    </div>
                </div>
                <!-- <button class="buttonGuanzhu" @click="guanzhuContent">
                    <i class="iconfont icon-success_no_circle" v-if="isGuanzhu"></i>
                    <i class="iconfont icon-jia" v-if="!isGuanzhu"></i>
                    <span v-if="!isGuanzhu">关注</span>
                    <span v-if="isGuanzhu">已关注</span>
                </button> -->
            </div>
            <div class="content" v-html="detailAll.content" id="contentdeCon"></div>
            <div class="numberStyle" v-if="detailAll.content">阅读数：{{number}}</div>
        </div>
        <div style="width:100%;height:8px;background:#F0F0F0"></div>
        <!-- <div class="commentContent commentContentOther" v-if="dataLIst&&dataLIst.length">
			<div class="columnTitle">-- 推荐 --</div>
            <newslist :listcontent="dataLIst"></newslist>
		</div> -->
        <div style="width:100%;height:8px;background:#F0F0F0"></div>
        <!-- 评论 -->
		<div class="commentContent">
			<div class="columnTitle">-- 评论区 --</div>
			<ul v-show="commentList.length !== 0" class="columnContent">
				<li v-for="(item, index) in commentList" :key="index">
                    <img :src="item.photo?item.photo:require('../assets/images/user.png')" alt="">
                    <div class="rootComment">
                        <div class="userInfo"><span>{{item.creatorName ? item.creatorName : '未知用户'}}</span><span @click="onReply(item)">回复</span></div>
                        <p>{{item.content}}</p>
                        <div class="childComment" v-for="(itemChild, index) in item.commentEntityList" :key="index">
                            <img :src="itemChild.photo?itemChild.photo:require('../assets/images/user.png')" alt="">
                            <div>
                                <div class="userInfo">
                                    <span>{{itemChild.creatorName ? itemChild.creatorName : '未知用户'}}
                                        <b style="color: red">@{{itemChild.parentName ? itemChild.parentName : '未知用户'}}</b>
                                    </span>
                                    <!-- <span @click="onReply(itemChild)">回复</span> -->
                                </div>
                                <p>{{itemChild.content}}</p>
                            </div>
                        </div>
                    </div>
				</li>
			</ul>
            <div v-show="commentList.length == 0" class="noComment">
                暂无评论
            </div>
		</div>
    </div>
</template>

<script>
import Vue from 'vue';
// import newslist from "./cllComponents/newsListDetail"
import { Toast } from 'vant';
Vue.use(Toast);
import loading from "./cllComponents/loading"
export default {
    components:{
        loading,
        // newslist
    },
    data(){
        return{
            show: false,            // 弹出评论输入控件
            detailAll:null,         // 内容
            thumbBlock:true,        // 封面图
            showFabulous: false,    // 是否点赞
            showCollection: false,    // 是否收藏
            commentList: [],
            viewpoint: '',          // 观点看法
            viewpointParent: null,  // 如果是对评论进行评论，则此变量存放被评论的id
            dataLIst:[], //推荐数据
            number:0 , //阅读数
            isGuanzhu:false ,//是否关注
            matrixIdJu:""
        }
    },
    created() {
        var id=window.location.href.split('&judge')[0].split('?id=')[1];
        if(window.location.href.search("&judge")==-1){
            this.datalinkDetail(id);
        }else{
            this.datalinkDetailJava(id);
        }
        this.readNumber(id); //阅读数
        // this.recommend(); //推荐
    },
    watch: {
        show: function(dNew) {
            if(!dNew) {
                // 保证在关闭评论框后保存的评论父级id为空
                this.viewpointParent = null;
            }
        }
    },
    methods:{
        // 获取微信的token值
        wxinTokenLink(){
            this.$nextTick(()=>{
                var news = this.shareData();
                this.$http.post(this.javalinkTwo+'usercenter/wxShare/sendMessageWx?url='+encodeURIComponent(window.location.href))
                .then(resp=>{
                    // 通过config接口注入权限验证配置 
                    window.wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: resp.data.appid, // 必填，公众号的唯一标识
                        timestamp: resp.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: resp.data.noncestr, // 必填，生成签名的随机串
                        signature: resp.data.signature,// 必填，签名
                        jsApiList: ['updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表
                    });
                    // 通过ready接口处理成功验证
                    window.wx.ready(function (){   //需在用户可能点击分享按钮前就先调用
                        window.wx.updateAppMessageShareData({ 
                            title:news.title,  // 分享标题
                            desc:news.describe,   // 分享描述
                            link:news.url,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl:news.thumb, // 分享图标
                            success: function () {
                                console.log("朋友");
                            }
                        }),
                        window.wx.updateTimelineShareData({ 
                            title:news.title,  // 分享标题
                            desc:news.describe,   // 分享描述
                            link:news.url,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl:news.thumb, // 分享图标
                            success: function () {
                                console.log("朋友圈");
                            }
                        })
                    });
                });
            })
        },
         // 分享
        shareData(){
            var describe = document.getElementById("contentdeCon").innerText.replace(/&nbsp;/g,"").slice(0,50);
            var link;
            if(window.location.href.search("&judge")==-1){
                if(this.detailAll.linkerapp){
                    link=this.detailAll.linkerapp; //链接
                }else{
                    link=window.location.href.split("#/")[0]+"?#/sharePageTwo?id="+this.detailAll.id; //链接
                }
            }else{
                link=window.location.href.split("#/")[0]+"?#/sharePageTwo?id="+this.detailAll.medid_id+'&judge'; //链接
            }
            return {url:link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe};
            // this.shareContent({url: link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe});
        },
        // 关注
        followCancelFollow(id,addOrMove,uid){
            this.$http.get(this.javalinkTwo + 'contribution/matrix/userAttentionMatrix',
            {params:{
                matrixId:id,
                addOrMove:addOrMove,
                uid:uid
            }})
            .then(resp=>{
                if(resp.data.success){
                    this.isGuanzhu=!this.isGuanzhu;
                    this.toReload('whole'); //刷新全部
                }else{
                    alert(resp.data.message);
                }
            });
        },
        // 没有关注跳登录
        guanzhuContent(){
            if(window.localStorage.civilizationId){
                var useid=window.localStorage.civilizationId;
                this.followCancelFollow(this.matrixIdJu,!this.isGuanzhu,useid);
                return false;
            }
            this.login();
        },
        // 获取数据
        datalinkDetail(id){
            this.$http.get(this.phplinkTwo+'app/index.php?c=content&f=detailsContent&id='+id)
            .then(resp=>{
                if(resp.data.status=='ok'){
                    this.detailAll = resp.data.content;
                    this.detailAll.content=this.detailAll.content.replace(/&lt;/g,'<').replace(/&gt;/g,'>');
                    this.wxinTokenLink(); //微信分享
                    this.isPoint(); //点赞
                    this.getCommentList(); //评论
                    this.isCollention(); //收藏
                    if(this.detailAll.matrix_judge==1){
                        this.concerned(this.detailAll.medid_id); //关注判断
                    }
                }
            });
        },
        // 判断有没有关注
        concerned(id){
            var uid="";
            if(window.localStorage.civilizationId){
                uid=window.localStorage.civilizationId;
            }
            this.$http.get(this.javalinkTwo+'contribution/matrix/userAttention?id='+id+"&uid="+uid)
            .then(resp=>{
                if(resp.data.success){
                    if(resp.data.data.attention=="false"){
                        this.isGuanzhu=false; //是否关注
                    }else{
                        this.isGuanzhu=true; //是否关注
                    }
                    
                    this.matrixIdJu=resp.data.data.matrixId; //矩阵id
                }
            });
        },
        // java详情
        datalinkDetailJava(id){
            this.$http.get(this.javalinkTwo+'contribution/matrix/selectId?id='+id)
            .then(resp=>{
                // console.log(resp.data)
                if(resp.data.success){
                    this.detailAll = resp.data.data;
                    this.detailAll.content=this.detailAll.content.replace(/&lt;/g,'<').replace(/&gt;/g,'>');
                     this.wxinTokenLink(); //微信分享
                    this.isPoint(); //点赞
                    this.getCommentList(); //评论
                    this.isCollention(); //收藏
                    this.concerned(this.detailAll.medid_id); //关注判断
                }
            });
        },
        readNumber(id){
            this.$http.get(this.javalinkTwo+'contribution/readnum/addReadNum?contributionId='+id)
            .then(resp=>{
                if(resp.data.success){
                    this.number=resp.data.data;
                }
            });
        },
        recommend(){
            this.$http.get(this.phplinkTwo+'app/index.php?c=index&f=recommendNews')
            .then(resp=>{
                if(resp.data.status=='ok'){
                    this.dataLIst=resp.data.hds_list;
                //    console.log(resp.data);
                }
            });
        },
        // 回复评论
        onReply(value) {
            // 评论前判断是否已经登录
            if(!this._judgeLogin()) return;
            this.show = true;
            this.viewpointParent = value
        },
        // 判断是否登录
        _judgeLogin() {
            if(!window.localStorage.civilizationId){
                this.login();
                return false;
            }else {
                return true;
            }
        },
        // 发表按钮
        onPublish() {
            if(!this.viewpoint) {
                return Toast('评论不能为空');
            }
            var inceptionTemp, parentIdTemp;
            if(!this.viewpointParent) {
                inceptionTemp = 0;
                parentIdTemp = 0;
            }else {
                inceptionTemp = this.viewpointParent.inception ? this.viewpointParent.inception : this.viewpointParent.id;
                parentIdTemp = this.viewpointParent.id;
                if(!parentIdTemp){
                    parentIdTemp=0;
                }
            }
            this.$http.post(this.javalinkTwo + "contribution/comment/memberInsert",
                JSON.stringify({
                    "inception": inceptionTemp,                   
                    // 第一个评论id 如果此评论就是第一个，则为0
                    "parentId": parentIdTemp,                      // 对当前所评论回复的id，如果为第一个则为0
                    "sourceType": 1,                                    // 0ugc 1标准推送 2新媒体
                    "contributionType": "0",                            // 0融媒体 1文明实践中心
                    "contributionId": this.detailAll.medid_id,                           // 文稿id
                    "creatorType": 1,                                   // 1会员 2管理员
                    "creatorId": window.localStorage.civilizationId,              // 创建者id
                    "creatorName": "",                                  // 创建者姓名
                    "content": this.viewpoint,   
                    "parentName":""  // 评论内容
                }),
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            ).then(resp => {
                if(resp.data.success) {
                    Toast.success("发表成功");
                    this.show = false;
                    this.viewpoint = "";
                    this.getCommentList();
                }else {
                    Toast.fail("发表失败");
                }
            }).catch(err => {
                console.log(err);
            })
        },
        // 点赞
        onFabulous() {
            if(!this._judgeLogin()) return;
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/givePraisePUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(() => {
                // console.log(resp)
                if(this.detailAll.matrix_judge==1){
                    this.toReload('whole'); //刷新全部
                }
                this.showFabulous = true;
            })
        },
        // 取消点赞
        onCancelFabulous() {
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/removePraisePUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(() => {
                // console.log(resp)
                if(this.detailAll.matrix_judge==1){
                    this.toReload('whole'); //刷新全部
                }
                this.showFabulous = false;
            })
        },
        // 收藏
        onCollection() {
            if(!this._judgeLogin()) return;
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/giveCollectPUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(() => {
                // console.log(resp)
                this.showCollection = true;
            })
        },
        //  取消收藏
        onCancelCollection() {
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/removeCollectPUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(() => {
                // console.log(resp)
                this.showCollection = false;
            })
        },
        // 视频播放
        vedioOpenPlay(){
            var video = document.getElementById('datailVedio');
            if(video){
                if(video.paused){//如果已暂停则播放
                    this.thumbBlock=false;
                    video.play();//暂停控制
                }
            }
        },
        // 获取评论列表接口
		getCommentList() {
			this.$http.get(this.javalinkTwo + "contribution/comment/queryComment",
				{
					headers: {},
					params: {
						page: 1,
						rows: 10,
						contributionType: 0,
						contributionId: this.detailAll.medid_id,
						commentId: 0,
						sourceType: 1
					}
				}
			).then(resp => {
				this.commentList = resp.data.data.items;
			}).catch(err => {
				console.log(err)
			})
        },
        // 检测当前文章是否已经点赞 
        isPoint() {
            // console.log(this.detailAll);
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/memberIsPraisePUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(resp => {
                // console.log(resp);
                this.showFabulous = resp.data.data;
            }).catch(err => {
                console.log(err);
            })
        },
        // 检测当前文章是否已经被收藏
        isCollention() {
            this.$http.get(this.javalinkTwo + "contribution/ContributionOperation/memberIsCollectPUB",
                {
                    headers: {},
                    params: {
                        memberId: window.localStorage.civilizationId,
                        contributionId: this.detailAll.medid_id
                    }
                }
            ).then(resp => {
                // console.log(resp);
                this.showCollection = resp.data.data;
            }).catch(err => {
                console.log(err);
            })
        },
    }
}
</script>
<style>
    .sharePageContent .content{
        text-align: justify;
        /* font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53; */
        line-height: 0.62rem;
        font-size: 0.38rem;
    }
    .content *{
        max-width: 100%!important;
    }
    .sharePageContent .content img{
        height: auto!important;
    }
    .sharePageContent .content video{
        height: auto!important;
    }
</style>
<style lang="less" scoped>
    .contentTitltle{
        font-size: 0.44rem;
        line-height: .64rem;
        // font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53;
        color: #222;
        font-weight: 600;
        padding: .3rem 0;
        /* font-weight: 700; */
    }
    .F9efPzfcE5tLO0cygLjpo {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0.3rem;
        ._1rui3RTB5brFqhZq5nYylv {
            -webkit-box-flex: 1;
            -webkit-flex: 1 1;
            -ms-flex: 1 1;
            flex: 1 1;
            height: 0;
            border-bottom: 1px solid #eaeaea;
        }
        .Q46HXxwObg2rmy-Ms7f8I {
            color: #9a9a9a;
            font-size: .26rem;
            margin-left: .1rem;
            margin-right: .1rem;
        }
    }
    .numberStyle{
        width: 100%;
        text-align:right;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        color: #999;
    }
    .contentDetail{
        width: 100%;
        height: 100%;
    }
    .contentAll{
        width: 100%;
        /* height: 100%; */
        padding: 15px;
        box-sizing: border-box;
        font-size: 16px;
    }
    .content{
        width: 100%;
    }
    .vedioContent{
        width: 100%;
        height: 190px;
        object-fit: contain;
    }
    // .contentTitltle{
    //     font-size: 20px;
    //     /* font-weight: 700; */
    // }
    .sourceAndTime{
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        color: #999;
    }
    .sourceFont {
        width: 78%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .sourceAndTimeJava{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .sourceFontnei {
        font-size: 12px;
        margin-left: 10px;
    }
    .buttonGuanzhu {
        width: 22%;
        background: #fff;
        outline: none;
        border: 1px solid #E32416;
        color: #E32416;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 5px;
        border-radius: 100px;
    }
    .buttonGuanzhu .iconfont{
        font-size: 12px;
    }
    .sourceFontImg {
        width: 30px;
        height: 30px;
    }
    .sourceFontImg  img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
    .shareContent{
        position: fixed;
        bottom: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        background: rgba(0,0,0,0.4);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .shareContent span{
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #fff;
        margin-left: 2px;
    }
    .shareContent span:last-child{
        margin-right: 2px;
    }


    .vedioStyleBox{
        width: 100%;
        height: 190px;
        position: relative;
        /* margin-bottom: 12px; */
    }
    .coverImg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 999;
    }
    .thumbImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .vedioButtonSty{
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        z-index: 1000;
    }
    .vedioStyle{
        width: 100%;
        height: 190px;
        object-fit: contain;
    }
    .userOpeeration {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        padding: 10px 15px;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;;
        position: fixed;
        bottom: 0;
        border-top: 1px solid #ddd;
    }
    .userOpeeration .iconfont{
        font-size: 28px;
    }
    .userOpeeration .shoucang{
        font-size: 25px;
    }
    .userOpeeration > div {
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .userOpeeration .comment{
        line-height: 20px;
        width: calc(100% - 150px);
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 5px 8px;
        background-color: #fff;
        color: #666;
        font-size: 12px;
        background: rgb(250, 250, 250,0.1);
    }
    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    .commentBlock {
        width: 100%;
        padding: 10px 20px;
        background-color: #fff;
    }
    .commentBlock textarea {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
          font-size: 16px;
    }
    .commentBlock div {
        display: flex;
        justify-content: space-between;
    }
    .commentBlock div button {
        font-size: 14px;
        outline: none;
        border: 0;
        background-color: #fff;
        padding: 3px 8px;
    }
    .commentContent {
        width: 100%;
        margin-bottom: 55px;
        .columnTitle {
            text-align: center;
            padding: 5px 0;
        }
        .columnContent {
            height: calc(100% - 80px);
            li {
                padding: 10px 15px;
                overflow: hidden;
                display: flex;
                img {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: #107da8;
                    padding: 3px;
                    box-sizing: border-box;
                }
                .rootComment {
                    width: calc(100% - 22px);
                    padding: 0 10px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #efefef;
                    padding-bottom: 10px;
                    .userInfo {
                        display: flex;
                        justify-content: space-between;
                        line-height: 22px;
                        span {
                            font-size: 12px;
                            &:last-child {
                                color: #bbb;
                            }
                        }
                    }
                    p {
                        font-size: 14px;
                    }
                    .childComment {
                        overflow: hidden;
                        display: flex;
                        margin-top: 10px;
                        padding-top: 10px;
                        border-top: 1px solid #efefef;
                        > div {
                            width: calc(100% - 22px);
                            padding-left: 10px;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
        .noComment {
            text-align: center;
            height: 100px;
            line-height: 100px;
        }
    }
    .commentContentOther{
        margin-bottom: 0px;
    }
</style>